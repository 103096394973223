import { registerApplication, start } from "single-spa";
import { authService } from "@talygen/authservice";
import initAuthService from "./initAuthService";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as prefix from "./activity-functions";
if (
  //!localStorage.getItem("oidc.user:https://devlogin.talygen.devlabs.com:talygenspa")
  // !localStorage.getItem("oidc.user:https://applogin.talygen.com:talygenspa")
  // !localStorage.getItem("oidc.user:https://qatestlogin.talygen.com:talygenspa")
  // !localStorage.getItem("oidc.user:https://stagelogin.talygen.com:talygenspa")
   !localStorage.getItem("oidc.user:https://usiclogin.talygen.com:talygenspa")
) {
  if (
    location.pathname == "/DownloadPublicFile" ||
    location.pathname == "DownloadPublicFolder"
  ) {
    registerApplication({
      name: "@talygen/docstorage",
      //app: () => System.import("http://localhost:9005/app.js"),
      //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/app.js"),
      //app: () => System.import("https://talygenv.github.io/talygenui/Stage/DocStorage/dist/app.js"),
      app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/DocStorage/dist/app.js"),
      activeWhen: (location) =>
        prefix.prefix(location, ["DownloadPublicFile", "DownloadPublicFolder"]),
      customProps: {
        userProfile: userProfileInfo,
        classicEditor: ClassicEditor,
        ckEditor: CKEditor,
      },
    });
  } else {
    initAuthService(location.pathname);
    var userProfileInfo = null;
    authService.getUser().then(function (response) {
      userProfileInfo = response;
      debugger;
      registerApplication({
        name: "@talygen/navbar",
        //app: () => System.import("@talygen/navbar"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/NavBar/dist/app.js"),
        app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/NavBar/dist/app.js"),
        activeWhen: "/",
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/crm",
        //app: () => System.import("http://localhost:9003/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CRM/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CRM/dist/app.js"),
        app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CRM/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "CRM",
            "Brand",
            "CommunicationModes",
            "CRMReport",
            "User",
            "logout",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/expenses",
        //app: () => System.import("http://localhost:9004/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Expense/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Expense/dist/app.js"),
        app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/Expense/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Expenses",
            "ExpenseApproval",
            "ExpenseReport",
            "ExpensesHistory",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/docstorage",
        //app: () => System.import("http://localhost:9005/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/DocStorage/dist/app.js"),
        app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/DocStorage/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "File",
            "ViewFileDetails",
            "RecyleBin",
            "Storage",
            "MyDashboard",
            "ViewFolderDetails",
            "AdvancedSetup",
            "UserStorageReport",
            "UserStorage",
            "ManageTags",
            "DownloadPublicFile",
            "DownloadPublicFolder",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/ticketing",
        app: () => System.import("http://localhost:9006/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Ticketing/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "FSM",
            "Ticketing",
            "RuleEngine",
            "ServiceAppointment",
            "FormBuilder",
            "ReplyTemplate",
            "TicketReport",
            "KnowledgeBase",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/assetmanagement",
        //app: () => System.import("http://localhost:9007/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/AssetManagement/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/AssetManagement/dist/app.js"),
        app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/AssetManagement/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Asset",
            "AssetAssignment",
            "AssetRequisition",
            "AssetCatalog",
            "AssetReport",
            "AdvancedSetups",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/commonui",
        //app: () => System.import("http://localhost:9008/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CommonUi/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CommonUi/dist/app.js"),
        app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CommonUi/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "HelpAndGuide",
            "HelpArticles",
            "UserManual",
            "HelpArticlesPerModule",
            "VideoTutorials",
            "Report",
            "SetupGuides",
            "Dashboard",
            "unauthorized",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/timer",
        app: () => System.import("http://localhost:9009/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Timer/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Timer/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "TimeTracking",
            "TimerReports",
            "TimesheetApproval",
            "Timer",
            "WorkDiary",
            "Project",
            "Timesheet",
            "TimeApproval",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/approvalmodule",
        app: () => System.import("http://localhost:9010/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Approval/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Approval/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "ApprovalRule",
            "ApprovalChain",
            "ApprovalGroup",
            "ApprovalReport",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/customlayout",
        app: () => System.import("http://localhost:9011/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/customlayout/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, ["CustomLayout", "Company", "Custom"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/companysetup",
        app: () => System.import("http://localhost:9012/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/customlayout/dist/app.js"),
        activeWhen: (location) => prefix.prefix(location, ["Company", "AnnouncementMessage","CompanyHoliday","Category","Channel","Department","Designation","Shift","Skill","Roles","Break","CannedReply","CompanyNotification","ContactType","Greeting","IndustryType","Immigration","Location","LoginAuthorization","ProvisionalRight","ReviewTemplate","Status","Storage"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
    });
  }
} else {
  var userProfileInfo = JSON.parse(
    //localStorage.getItem("oidc.user:https://devlogin.talygen.devlabs.com:talygenspa")
    //  localStorage.getItem("oidc.user:https://applogin.talygen.com:talygenspa")
    //  localStorage.getItem("oidc.user:https://qatestlogin.talygen.com:talygenspa")
    //localStorage.getItem("oidc.user:https://stagelogin.talygen.com:talygenspa")
    localStorage.getItem("oidc.user:https://usiclogin.talygen.com:talygenspa")
  );
  registerApplication({
    name: "@talygen/navbar",
    //app: () => System.import("@talygen/navbar"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/NavBar/dist/app.js"),
    app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/NavBar/dist/app.js"),
    
    activeWhen: "/",
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/crm",
    //app: () => System.import("http://localhost:9003/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CRM/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CRM/dist/app.js"),
    app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CRM/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "CRM",
        "Brand",
        "CommunicationModes",
        "CRMReport",
        "User",
        "logout",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/expenses",
    app: () => System.import("http://localhost:9004/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Expense/dist/app.js"),
    // app: () => System.import("https://talygenv.github.io/talygenui/Stage/Expense/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Expenses",
        "ExpenseApproval",
        "ExpenseReport",
        "ExpensesHistory",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/docstorage",
    app: () => System.import("http://localhost:9005/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/DocStorage/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "File",
        "ViewFileDetails",
        "RecyleBin",
        "Storage",
        "MyDashboard",
        "ViewFolderDetails",
        "AdvancedSetup",
        "UserStorageReport",
        "UserStorage",
        "ManageTags",
        "DownloadPublicFile",
        "DownloadPublicFolder",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/ticketing",
    app: () => System.import("http://localhost:9006/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Ticketing/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "FSM",
        "Ticketing",
        "RuleEngine",
        "ServiceAppointment",
        "FormBuilder",
        "ReplyTemplate",
        "TicketReport",
        "KnowledgeBase",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/assetmanagement",
    app: () => System.import("http://localhost:9007/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/AssetManagement/dist/app.js"),
    // app: () => System.import("https://talygenv.github.io/talygenui/Stage/AssetManagement/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Asset",
        "AssetAssignment",
        "AssetRequisition",
        "AssetCatalog",
        "AssetReport",
        "AdvancedSetups",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/commonui",
    //app: () => System.import("http://localhost:9008/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CommonUi/dist/app.js"),
    // app: () => System.import("https://talygenv.github.io/talygenui/Stage/CommonUi/dist/app.js"),
    app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CommonUi/dist/app.js"),
    
    activeWhen: (location) =>
      prefix.prefix(location, [
        "HelpAndGuide",
        "HelpArticles",
        "UserManual",
        "HelpArticlesPerModule",
        "VideoTutorials",
        "Report",
        "SetupGuides",
        "Dashboard",
        "unauthorized",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/timer",
    app: () => System.import("http://localhost:9009/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Timer/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Timer/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "TimeTracking",
        "TimerReports",
        "TimesheetApproval",
        "Timer",
        "WorkDiary",
        "Project",
        "Timesheet",
        "TimeApproval",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/approvalmodule",
    app: () => System.import("http://localhost:9010/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Approval/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Approval/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "ApprovalRule",
        "ApprovalChain",
        "ApprovalGroup",
        "ApprovalReport",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/customlayout",
    app: () => System.import("http://localhost:9011/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/customlayout/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, ["CustomLayout", "Company", "Custom"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/companysetup",
    app: () => System.import("http://localhost:9012/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/customlayout/dist/app.js"),
    activeWhen: (location) => prefix.prefix(location, ["Company", "AnnouncementMessage","CompanyHoliday","Category","Channel","Department","Designation","Shift","Skill","Roles","Break","CannedReply","CompanyNotification","ContactType","Greeting","IndustryType","Immigration","Location","LoginAuthorization","ProvisionalRight","ReviewTemplate","Status","Storage"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
}
start();
